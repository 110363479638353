.main-footer {
  color: white;

  margin: auto;
  background: linear-gradient(90deg, #2837ef 0%, #1557e3 100%);
  padding-top: 2em;

  bottom: 0;
  width: 100%;
}

.divider{
  /* background: linear-gradient(180deg, #fff, #fff, #fff); */
  background: #fff;
  height: 150px;
}

.rowFooter {
  margin-left: 6%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10;
  margin-bottom: 5px;
}
.stayConnected {
  width: 150%;
  text-align: center;
  margin-left: 50%;
  font-size: calc(0.5vw + 0.5vh + 0.5vmin);
}
.findOut {
  width: 120%;
  margin-left: -15%;
  text-align: center;
  font-size: calc(0.5vw + 0.5vh + 0.5vmin);
}
.home1 {
  text-align: left;
  width: 85%;
  margin-left: -90%;
  font-size: calc(0.5vw + 0.5vh + 0.5vmin);
}
.aboutUs {
  text-align: left;
  width: 85%;
  margin-left: -90%;
  margin-top: -80px;
  font-size: calc(0.5vw + 0.5vh + 0.5vmin);
}
.bookAppointment {
  text-align: left;
  width: 65%;
  margin-left: -90%;
  margin-top: -40px;
  font-size: calc(0.5vw + 0.5vh + 0.5vmin);
}
/* .menu:hover{
    text-decoration: underline;
} */

.divider{
  background: linear-gradient(180deg, transparent, #ccd8ed, transparent);
  height: 150px;
}

.footerRow {
  padding: 5px;
  width: 100%;
  text-align: right;
  background: #000;
}
.social-media-icons {
  text-align: right;
  width: 150%;
  margin-top: -27%;
  margin-left: 30%;
  background-color: red;
}
/* .social-media-icons{
    text-align:right; 
    width:150%; 
    margin-left: 15%;
} */
.circle-icon {
  background: #ccd8ed;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #2837ef;
}
.circle-icon:hover {
  background: white;
}
.button-div {
  width: 85%;
  margin-left: -0%;
  text-align: center;
  margin-top: -23%;
}
.button {
  /* height:40px; 40px 250px */
  /* width:250px;  */
  height: 70%;
  width: 100%;
  font-size: 0.8vw;
  color: #013da5;
  background-color: #ccd8ed;
}

.button:hover {
  background-color: #fff;
}
.vl3 {
  background: linear-gradient(180deg, transparent, #ccd8ed, transparent);
  background-position: 50%;
  background-repeat: repeat-y;
  background-size: 1px auto;
  /* height: 120px;  */
  height: 100%;
  margin-left: 85%;
  width: 10%;
  margin-top: 0;
}
.vl2 {
  background: linear-gradient(180deg, transparent, #ccd8ed, transparent);
  background-position: 50%;
  background-repeat: repeat-y;
  background-size: 1px auto;
  height: 120px;
  /* height: 100%; */
  margin-left: -5%;
  width: 2%;
  margin-top: 0;
}

/* use after or active here */
/* .vl2{  */
/* background: linear-gradient(180deg, transparent, #ccd8ed, transparent); */
/* background: -webkit-linear-gradient(top,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.7) 100%); Chrome10+,Safari5.1+ */
/* background: -webkit-linear-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.5)), color-stop(100%,rgba(0,0,0,0.7)));  */
/* background-position: 50%; */
/* background-repeat: repeat-y; */
/* background-size: 1px auto; */
/* height: 120px; */
/* margin-left: -19%; */
/* margin-top: -3.5%; */
/* } */
/* .vl3 {
    background: linear-gradient(180deg, transparent, #ccd8ed, transparent);
    background-position: 50%;
    background-repeat: repeat-y;
    background-size: 1px auto;
    height: 120px;
    margin-left: 26%;
    margin-top: -8.9%;
} */
hr {
  margin-right: 5%;
  margin-bottom: 1%;
}

@media only screen and (max-width: 414px) {
  .main-footer {
    color: white;

    margin: auto;
    background: linear-gradient(90deg, #2837ef 0%, #1557e3 100%);
    padding-top: 1em;
    /* position: ; */
    bottom: 0;
    width: 100%;
  }
  .rowFooter {
    margin-left: 6%;
    font-size: 8px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5;
    padding: 0% 1% 0% 1%;
    margin-bottom: 5px;
    /* position: relative; */
  }
  .stayConnected {
    text-align: center;
    width: 100%;
    margin-left: 30%;
  }
  .vl3 {
    background: linear-gradient(180deg, transparent, #ccd8ed, transparent);
    background-position: 50%;
    background-repeat: repeat-y;
    background-size: 1px auto;
    height: 80px;
    margin-left: 75%;
    width: 10%;
    margin-top: 0;
  }
  .vl2 {
    background: linear-gradient(180deg, transparent, #ccd8ed, transparent);
    background-position: 50%;
    background-repeat: repeat-y;
    background-size: 1px auto;
    /* height: 80px;
    margin-left: 10%; */
    /* width: 10%; */
    margin-top: 0;
  }
  .findOut {
    width: 125%;
    margin-left: -15%;
    text-align: center;
  }
  .home1 {
    text-align: left;
    width: 100%;
    margin-left: -70%;
  }
  .aboutUs {
    text-align: left;
    width: 100%;
    margin-left: -70%;
    margin-top: -65px;
  }
  .bookAppointment {
    text-align: left;
    width: 120%;
    margin-left: -54px;
    margin-top: -50px;
  }
  .social-media-icons {
    text-align: left;
    width: 180%;
    margin-top: -77%;
    margin-left: -5%;
  }
  .button-div {
    width: 125%;
    margin-left: -3%;
    text-align: left;
    margin-top: -53%;
  }
  .button {
    height: 20px; /*40px 250px*/
    width: 85px;
    font-size: 4px;
    color: #013da5;
    background-color: #ccd8ed;
  }

  .button:hover {
    background-color: #fff;
  }
  .circle-icon {
    background: #ccd8ed;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    color: #2837ef;
  }
  .circle-icon:hover {
    background: white;
  }
  .footerRow {
    margin-top: -5%;
    padding: 5px;
    width: 100%;
    text-align: center;
    background: #000;
    font-size: x-small;
  }
}
@media only screen and (max-width: 375px) {
  .main-footer {
    color: white;

    margin: auto;
    background: linear-gradient(90deg, #2837ef 0%, #1557e3 100%);
    padding-top: 1em;
    /* position: ; */
    bottom: 0;
    width: 100%;
  }
  .rowFooter {
    margin-left: 6%;
    font-size: 8px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5;
    padding: 0% 1% 0% 1%;
    margin-bottom: 5px;
    /* position: relative; */
  }
  .stayConnected {
    text-align: center;
    width: 100%;
    margin-left: 30%;
  }
  .vl3 {
    background: linear-gradient(180deg, transparent, #ccd8ed, transparent);
    background-position: 50%;
    background-repeat: repeat-y;
    background-size: 1px auto;
    height: 80px;
    margin-left: 75%;
    width: 10%;
    margin-top: 0;
  }
  .vl2 {
    background: linear-gradient(180deg, transparent, #ccd8ed, transparent);
    background-position: 50%;
    background-repeat: repeat-y;
    background-size: 1px auto;
    /* height: 80px;
    margin-left: 10%;
    width: 10%; */
    margin-top: 0;
  }
  .findOut {
    width: 125%;
    margin-left: -15%;
    text-align: center;
  }
  .home1 {
    text-align: left;
    width: 100%;
    margin-left: -70%;
  }
  .aboutUs {
    text-align: left;
    width: 100%;
    margin-left: -70%;
    margin-top: -65px;
  }
  .bookAppointment {
    text-align: left;
    width: 120%;
    margin-left: -52px;
    margin-top: -50px;
  }
  .social-media-icons {
    text-align: left;
    width: 180%;
    margin-top: -77%;
    margin-left: -5%;
  }
  .button-div {
    width: 125%;
    margin-left: -3%;
    text-align: left;
    margin-top: -53%;
  }
  .button {
    height: 20px; /*40px 250px*/
    width: 85px;
    font-size: 4px;
    color: #013da5;
    background-color: #ccd8ed;
  }

  .button:hover {
    background-color: #fff;
  }
  .circle-icon {
    background: #ccd8ed;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    color: #2837ef;
  }
  .circle-icon:hover {
    background: white;
  }
  .footerRow {
    margin-top: -5%;
    padding: 5px;
    width: 100%;
    text-align: center;
    background: #000;
    font-size: x-small;
  }
}

@media only screen and (max-width: 320px) {
  .main-footer {
    color: white;

    margin: auto;
    background: linear-gradient(90deg, #2837ef 0%, #1557e3 100%);
    padding-top: 1em;
    /* position: ; */
    bottom: 0;
    width: 100%;
  }
  .rowFooter {
    margin-left: 6%;
    font-size: 8px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5;
    padding: 0% 1% 0% 1%;
    margin-bottom: 5px;
    /* position: relative; */
  }
  .stayConnected {
    text-align: center;
    width: 100%;
    margin-left: 30%;
  }
  .vl3 {
    background: linear-gradient(180deg, transparent, #ccd8ed, transparent);
    background-position: 50%;
    background-repeat: repeat-y;
    background-size: 1px auto;
    height: 80px;
    margin-left: 65%;
    width: 10%;
    margin-top: 0;
  }
  .vl2 {
    background: linear-gradient(180deg, transparent, #ccd8ed, transparent);
    background-position: 50%;
    background-repeat: repeat-y;
    background-size: 1px auto;
    height: 80px;
    /* margin-left: 20%; */
    /* width: 10%; */
    margin-top: 0;
  }
  .findOut {
    width: 125%;
    margin-left: -15%;
    text-align: center;
  }
  .home1 {
    text-align: left;
    width: 100%;
    margin-left: -50%;
  }
  .aboutUs {
    text-align: left;
    width: 100%;
    margin-left: -50%;
    margin-top: -65px;
  }
  .bookAppointment {
    text-align: left;
    width: 150%;
    margin-left: -32px;
    margin-top: -50px;
  }
  .social-media-icons {
    /* text-align:center;  */
    width: 100%;
    margin-top: -91%;
    margin-left: 38%;
  }
  .button-div {
    width: 125%;
    margin-left: 0%;
    text-align: left;
    margin-top: -53%;
  }
  .button {
    height: 20px; /*40px 250px*/
    width: 70px;
    font-size: 4px;
    color: #013da5;
    background-color: #ccd8ed;
  }

  .button:hover {
    background-color: #fff;
  }
  .circle-icon {
    background: #ccd8ed;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    color: #2837ef;
  }
  .circle-icon:hover {
    background: white;
  }
  .footerRow {
    margin-top: -5%;
    padding: 5px;
    width: 100%;
    text-align: center;
    background: #000;
    font-size: x-small;
  }
}

@media only screen and (min-width: 900px) {
  .main-footer {
    color: white;
    margin: auto;
    background: linear-gradient(90deg, #2837ef 0%, #1557e3 100%);
    padding-top: 1em;
    bottom: 0;
    width: 100%;
    height: 161px;
  }

  .rowFooter {
    margin-left: 6%;
    font-size: 8px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5;
    padding: 0% 1% 0% 1%;
    margin-bottom: 5px;
  }
  .stayConnected {
    text-align: center;
    width: 100%;
    margin-left: 50%;
  }
  .vl3 {
    background: linear-gradient(180deg, transparent, #ccd8ed, transparent);
    background-position: 50%;
    background-repeat: repeat-y;
    background-size: 1px auto;
    /* height: 80px; */
    margin-left: 65%;
    width: 10%;
    /* margin-top: 0; */
  }
  .vl2 {
    background: linear-gradient(180deg, transparent, #ccd8ed, transparent);
    background-position: 50%;
    background-repeat: repeat-y;
    background-size: 1px auto;
    /* height: 80px; */
    /* margin-left: 20%; */
    /* width: 10%; */
    /* margin-top: 0; */
  }
  .findOut {
    width: 125%;
    margin-left: -15%;
    text-align: center;
  }
  .home1 {
    text-align: left;
    width: 100%;
    margin-left: -50%;
  }
  .home1:hover {
    text-decoration: underline;
  }
  .aboutUs {
    text-align: left;
    width: 100%;
    margin-left: -50%;
    margin-top: -80px;
  }
  .aboutUs:hover {
    text-decoration: underline;
  }
  .bookAppointment {
    text-align: left;
    width: 150%;
    margin-left: -50%;
    margin-top: -40px;
  }
  .bookAppointment:hover {
    text-decoration: underline;
  }
  .social-media-icons {
    text-align: left;
    width: 180%;
    /* margin-top: px; */
    margin-left: 45%;
  }
  .button-div {
    width: 100%;
    margin-left: -2%;
    text-align: left;
  }
  .button {
    height: 40px; /*40px 250px*/
    width: 95%;
    margin-top: 2%;
    color: #013da5;
    background-color: #ccd8ed;
  }

  .button:hover {
    background-color: #fff;
  }
  .circle-icon {
    background: #ccd8ed;
    width: 40px;
    height: 40px;
    /* width: 1.5vw;
    height: 1.5vw; */
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    /* line-height: 1.5vw; */
    color: #2837ef;
  }
  .circle-icon:hover {
    background: white;
  }
  /* .footerRow {
    margin-top: -5%;
    padding: 5px;
    width: 100%;
    text-align: center;
    background: #000;
    font-size: x-small;
  } */
}

/* .footerRow3{
    width: 100%;
    padding-bottom: 20px;
    margin-top: -1%;
}
.footerCol3{
    font-size: 17px;
    width: 13%;
    display: inline-block;
    margin-left: 5px;

}

.footerCol4{
    font-size: 18px;
    width: 24%;
    display: inline-block;
    margin-left: 47%;
}
.footerCol5{
    font-size: 16px;
    width: 24%;
    display: inline-block;
    margin-left: 35%;
}

.footerCol33{
    font-size: 16px;
    width: 25%;
    display: inline-block;
    margin-left: 5px;

} */
/* @media only screen and (max-width: 900px) {
  .main-footer {
    color: white;
    margin: auto;
    background: linear-gradient(90deg, #2837ef 0%, #1557e3 100%);
    padding-top: 1em;
    bottom: 0;
    width: 100%;
    height: 161px;
  }

  .rowFooter {
    margin-left: 6%;
    font-size: 8px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5;
    padding: 0% 1% 0% 1%;
    margin-bottom: 5px;
  }
  .stayConnected {
    text-align: center;
    width: 100%;
    margin-left: 30%;
  } */
/* .vl3 {
      background: linear-gradient(180deg, transparent, #ccd8ed, transparent);
      background-position: 50%;
      background-repeat: repeat-y;
      background-size: 1px auto;
      height: 80px;
      margin-left: 65%;
      width: 10%;
      margin-top: 0;
    }
    .vl2 {
      background: linear-gradient(180deg, transparent, #ccd8ed, transparent);
      background-position: 50%;
      background-repeat: repeat-y;
      background-size: 1px auto;
      height: 80px;
      margin-left: 20%;
      width: 10%;
      margin-top: 0;
    } */
/* .findOut {
    width: 125%;
    margin-left: -15%;
    text-align: center;
  }
  .home1 {
    text-align: left;
    width: 100%;
    margin-left: -50%;
  }
  .aboutUs {
    text-align: left;
    width: 100%;
    margin-left: -50%;
    margin-top: -80px;
  }
  .bookAppointment {
    text-align: left;
    width: 150%;
    margin-left: -50%;
    margin-top: -40px;
  }
  .social-media-icons {
    text-align: left;
    width: 180%;
    margin-top: -20%;
    margin-left: 5%;
  }
  .button-div {
    width: 100%;
    margin-left: -2%;
    text-align: left;
  }
  .button { */
/* height: 40px; 40px 250px */
/* width: 95%;
    margin-top: 2%;
    color: #013da5;
    background-color: #ccd8ed;
  } */

/* .button:hover {
    background-color: #fff;
  }
  .circle-icon {
    background: #ccd8ed;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    color: #2837ef;
  }
  .circle-icon:hover {
    background: white;
  } */
/* .footerRow {
      margin-top: -5%;
      padding: 5px;
      width: 100%;
      text-align: center;
      background: #000;
      font-size: x-small;
    } */
/* } */
