@import "~antd/dist/antd.css";
/* 
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
/* 
@font-face{
  font-family: 'Poppins';
  src:local('Poppins'), url(./assets//fonts//Poppins//Poppins-Regular.ttf) format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 */


 @font-face {
  font-family: "GalanoLight";
  src: url("./assets/fonts/galano/GalanoGrotesqueAltLight.otf")format("opentype");
}

@font-face {
  font-family: "GalanoBoldItalic";
  src: url("./assets/fonts/galano/GalanoGrotesqueAltBoldItalic.otf")format("opentype");
}


@font-face {
  font-family: "GalanoBold";
  src: url("./assets/fonts/galano/GalanoGrotesqueAltBold.otf")format("opentype");
}

@font-face {
  font-family: "GalanoLightItalic";
  src: url("./assets/fonts/galano/GalanoGrotesqueLightItalic.otf")format("opentype");
}

@font-face {
  font-family: "GalanoMediumItalic";
  src: url("./assets/fonts/galano/GalanoGrotesqueAltMediumItalic.otf")format("opentype");
}

@font-face {
  font-family: "GalanoMedium";
  src: url("./assets/fonts/galano/GalanoGrotesqueAltMedium.otf")format("opentype");
}

h1,h3,p1{
  font-family: GalanoBold;
}
p2{
  font-family: GalanoMediumItalic;
}
p3{
  font-family: GalanoBoldItalic;
}
p4{
  font-family: GalanoMedium;
}
p5{
  font-family: GalanoLight;
}
p{
  font-family: GalanoLightItalic;
}
.boxes{

  margin-top: 10px;
  background-color: #fff;
  width: 82%;
  height: 50px;
  overflow: hidden;
  box-shadow: 0 12px 18px -6px rgb(40 55 239 / 50%);
  border-radius: 10px;
  padding: 1.2%;
  text-align: left;
  color: #000;
}