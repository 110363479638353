:root {
  --primary: #fff;
}
.btn {
  padding: 8px 20px;
  border-radius: 25px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: #fff;
  color: #000;
  padding: 5px 15px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
  box-shadow: 0px 8px 15px rgba(8, 8, 8, 0.5);
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
  /* border-radius: 10px; */
}

.btn--medium:hover,
.btn--large:hover {
  transition: all 0.3s ease-out;
  /* background: #000; */
  background: linear-gradient(
    90deg,
    rgba(0, 212, 255, 1) 10%,
    rgba(9, 9, 121, 1) 100%,
    rgba(0, 212, 255, 1) 10%
  );
  color: #fff;
  transition: 250ms;
}
/* rgba(0, 212, 255, 1) 0%, */
