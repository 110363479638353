.Button-div {
  margin: auto;
  width: 80%;
  height: 50px;
  background: #000;
}

.Buy {
  width: 96%;
  height: 85%;
  background: #1557e3;
  border: 0px;
  padding: 12px;
  color: #fff;
  /* font-family:Arial, Helvetica, sans-serif; */
  /* font-size: 15px; */
  font-weight: bolder;
  text-align: center;
}
.DisabledBuy {
  width: 96%;
  height: 85%;
  background: lightgray;
  border: 0px;
  padding: 12px;
  color: #fff;
  /* font-family:Arial, Helvetica, sans-serif; */
  /* font-size: 15px; */
  font-weight: bolder;
  text-align: center;
}
.DisabledBuy:hover {
  width: 100%;
  height: 100%;
  transition: ease-in-out 0.3s;
  background: lightgray;
  border: 0px;
  padding: 15px;
  color: #fff;
  /* font-family:Arial, Helvetica, sans-serif; */
  /* font-size: 15px; */
  font-weight: bolder;
  text-align: center;
}
.Buy:hover {
  width: 100%;
  height: 100%;
  transition: ease-in-out 0.3s;
  background: #1557e3;
  border: 0px;
  padding: 15px;
  color: #fff;
  /* font-family:Arial, Helvetica, sans-serif; */
  /* font-size: 15px; */
  font-weight: bolder;
  text-align: center;
}
